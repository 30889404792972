* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  font-size: 100%;
  font-family: 'Red Hat Display';
}

.App {
  display: flex;
  overflow-y: scroll;
  font-family: 'Red Hat Display';
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}




a {
  text-decoration: none;
  color: #47ECF5;
}
button{
  cursor: pointer;
}
table {
  width: 90%;
  margin: auto;
  /* border: 1px solid lightgray; */
}

tbody {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
  font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
/* identical to box height */


color: #8C8888;
}

td {
    border-bottom: 1px solid lightgray;
      border-right: 1px solid lightgray;
      text-align: center;
}

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
.select {
  width: 300px;
height: 42px;
background: #F9F9F9;
border-radius: 12px;
font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 27px;
color: #9C9696;
outline: none;
border: none;
padding-left: 20px;
}

.buttonloader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #F66D00;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #F66D00;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationItem {
  /* border: 2px solid #888; */
  background: #fff;
  padding: 10px 15px;
  height: 20px;
  width: 20px;
  position: relative;
  margin: 30px 5px;
  cursor: pointer;

}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: #F66D00;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}

.currentPage {
  background: #000000 !important;
  color: #fff;
  font-size: 16px;
}

.paginationItem.active {
  border: 2px solid #F66D00;
  color: #F66D00;
  pointer-events: none;
}

.pagination-input-field::-webkit-outer-spin-button,
.pagination-input-field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.pagination-input-field {
  -moz-appearance: textfield;
}



.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}

.MuiBackdrop-root{
  background-color: unset !important;
}

.dropdown-content {
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  top: 60px;
  color: red;
  cursor: pointer;
  background: #ffffff;;
}